import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Breadcrumbs from "../components/seo/breadcrumbs"
import { getHrefLangs } from "../core/QueryTransformer"

import rehypeReact from "rehype-react"
import Bubble from "../components/bubble"
import SimpleBox from "../components/simple-box"

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { 
    bubble: Bubble,
    box: SimpleBox
  },
}).Compiler


export default function Template({ data, pageContext: { pages, locale, home } }) {

  const { singlePagePost, hreflangEdges, homePage } = data

  const { frontmatter, htmlAst } = singlePagePost
  let hreflangs = getHrefLangs(hreflangEdges.edges)

  let breadcrumbs = [
    {
      url: homePage.frontmatter.url,
      title: homePage.frontmatter.title,
    },
    {
      url: singlePagePost.frontmatter.url,
      title: singlePagePost.frontmatter.title,
    },
  ]

  return (
    <Layout
      hreflangs={hreflangs}
      pages={pages.filter(page => page.locale === frontmatter.locale)}
      title={frontmatter.title}
      description={frontmatter.description}
      locale={locale}
      home={home}
    >
      <SEO
        title={frontmatter.metatitle}
        hreflangs={hreflangs}
        locale={frontmatter.locale}
        description={frontmatter.metadescription}
      />
      <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>

      <div className="main-body inner-page">
      <section className="container">
        <div className="row">
          <div className="col-12">
            <div
              className="region-text"
            >              
            {
              renderAst(htmlAst)
            }
            </div>
          </div>
        </div>
      </section>

      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($url: String!, $locale: String!, $slug: String!) {
    singlePagePost: markdownRemark(
      frontmatter: { url: { eq: $url }, locale: { eq: $locale } }
    ) {
      htmlAst
      frontmatter {
        title
        heading
        metatitle
        locale
        region
        type
        description
        metadescription
        slug
        #featuredImage {
        #  childImageSharp {
        #    fluid(maxWidth: 800) {
        #      ...GatsbyImageSharpFluid
        #    }
        #  }
        #}
      }
    }
    homePage: markdownRemark(
      frontmatter: { locale: { eq: $locale }, type: { eq: "homepage" } }
    ) {
      frontmatter {
        title
        url
      }
    }    
    hreflangEdges: allMarkdownRemark(
      filter: { frontmatter: { slug: { eq: $slug } } }
    ) {
      edges {
        node {
          frontmatter {
            url
            locale
          }
        }
      }
    }
  }
`
